

















































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {email, password, required} from '@/utils/validationRules';
import IRegistrationForm from '@/types/IRegistrationForm';
import {NotificationTypes, notify, resolveError} from '@/utils/notifications';

@Component({name: 'RegistrationForm'})
export default class RegistrationForm extends Vue {

  step = 1;

  @Prop()
  onSubmit!: Function;

  loading: boolean = false;

  form: IRegistrationForm = {
    companyName: '',
    nip: '',
    mail: '',
    password: '',
    confirmPassword: '',
  };

  rules = {
    companyName: [required],
    nip: [required],
    mail: [required, email],
    password: [required, password],
    confirmPassword: [required, this.confirmPassword],
  };

  confirmPassword(value: string) {
    return value === this.form.password || this.$t('SYSTEM_ENTERED_PASSWORDS_MUST_BE_THE_SAME');
  }

  submitForm1() {
    // @ts-ignore
    if (!this.$refs.form1.validate()) {
      return
    }
    this.step = 2
  }

  async register() {
    // @ts-ignore
    if (this.$refs.form2.validate()) {
      this.loading = true;
      try {
        await this.onSubmit(this.form);
        notify(NotificationTypes.success, this.$t('SYSTEM_ACCOUNT_HAS_BEEN_REGISTERED'));
        await this.$router.push({name: 'Login'});
      } catch (e) {
        resolveError(e, 'register');
      } finally {
        this.loading = false;
      }
    }
  }
}
