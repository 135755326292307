









import {Component, Vue} from 'vue-property-decorator';
import RegistrationForm from '@/components/RegistrationForm.vue';
import IRegistrationForm from '@/types/IRegistrationForm';
import AuthService from '@/services/AuthService';

@Component({
  name: 'Registration',
  components: {RegistrationForm},
})
export default class Registration extends Vue {

  register(data: IRegistrationForm) {
    return AuthService.register(data);
  }

}
